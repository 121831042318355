import { IconButtonProps } from "@/components/IconButton/types/IconButtonProps";
import clsx from "clsx";
import { cloneElement, forwardRef, isValidElement } from "react";
import { tv } from "tailwind-variants";

const style = tv({
  base: "overflow-hidden inline-grid place-items-center place-content-center",
  variants: {
    variant: {
      primary: ["bg-black fill-white", "hover:bg-gray-800"],
      secondary: ["bg-white fill-black", "hover:bg-gray-50"],
      neutral: ["bg-gray-100 text-primary", "hover:bg-gray-200"],
      danger: [
        "bg-none fill-danger border-danger border",
        "hover:bg-red-500 hover:bg-opacity-5",
      ],
      outlined: ["text-primary border", "hover:bg-gray-50"],
      ghost: ["[&_svg]:fill-white text-white"],
    },
    size: {
      small: ["w-[24px] h-[24px]", "[&_svg]:w-[16px] [&_svg]:h-[16px]"],
      medium: ["w-[32px] h-[32px]", "[&_svg]:w-[16px] [&_svg]:h-[16px]"],
      large: ["w-[40px] h-[40px]", "[&_svg]:w-[24px] [&_svg]:h-[24px]"],
      auto: ["aspect-square"],
    },
    disabled: {
      true: "[&_svg]:!fill-gray-200 !bg-white !border !border-primary",
    },
    rounded: {
      true: "rounded-full",
      false: "rounded-xxs",
    },
  },
});

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton(
    {
      variant = "primary",
      size = "medium",
      disabled,
      rounded = false,
      asChild = false,
      children,
      ...buttonProps
    },
    ref,
  ) {
    const shouldActAsChild = asChild && isValidElement(children);

    return cloneElement(
      shouldActAsChild ? (
        disabled ? (
          <div aria-disabled />
        ) : (
          children
        )
      ) : (
        <button ref={ref} type="button" disabled={disabled} {...buttonProps} />
      ),
      {
        className: clsx(
          style({ variant, size, disabled, rounded }),
          "className" in buttonProps && buttonProps.className,
        ),
      },
      shouldActAsChild ? children.props.children : children,
    );
  },
) as React.FC<IconButtonProps>;
export default IconButton;
